import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// Auth Module
const AuthLayout = lazy(() => import('../Modules/Auth/Auth'));
const LoginLayout = lazy(() => import('../Modules/Auth/Login/Login'));
const SignupLayout = lazy(() => import('../Modules/Auth/Signup/Signup'));

const OrderLayout = lazy(() => import('../Modules/Order/Order'));
const HomeLayout = lazy(() => import('../Modules/Home/Home'));
const ServiceLayout = lazy(() => import('../Modules/Service/Service'));
const AboutLayout = lazy(() => import('../Modules/About/About'));
const Ourfaqlayout = lazy(() => import('../Modules/Ourfaq/Ourfaq'));
const Contactlayout = lazy(() => import('../Modules/Contact/Contact'));
const Pricelayout = lazy(() => import('../Modules/Price/Price'));

export const routes = () => [
  {
    path: '/',
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        index: true,
        element: <Navigate to='/home' />,
      },
      {
        path: '/order',

        element: <OrderLayout />,
      },

      {
        path: '/home',
        element: <HomeLayout />,
      },
      {
        path: '/service',
        element: <ServiceLayout />,
      },
      {
        path: '/about',
        element: <AboutLayout />,
      },
      {
        path: '/ourfaq',
        element: <Ourfaqlayout />,
      },
      {
        path: '/contact',
        element: <Contactlayout />,
      },
      {
        path: '/pricing',
        element: <Pricelayout />,
      },
    ],
  },
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Navigate to='/auth/login' />,
      },
      {
        path: 'login',
        element: <LoginLayout />,
      },
      {
        path: 'signup',
        element: <SignupLayout />,
      },
    ],
  },
];
