import './App.scss';
import { Suspense } from 'react';
import { routes } from './Routes/Routes';
import { useRoutes } from "react-router-dom";

function App() {
  return (<Suspense fallback={<div>Loading ...</div>}>
    <div style={{background: '#141414', minHeight: '100vh', overflow: 'auto'}}>

    {useRoutes(routes())}
    </div>
  </Suspense>)
}

export default App;
